/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import store, { persistor } from './redux/store'
import './index.scss'
// import "./sass/style.react.rtl.css"; // RTL version
import './_metronic/_assets/plugins/keenthemes-icons/font/ki.css'
// import 'socicon/css/socicon.css'
// import '@fortawesome/fontawesome-free/css/all.min.css'
import './_metronic/_assets/plugins/flaticon/flaticon.css'
import './_metronic/_assets/plugins/flaticon2/flaticon.css'
// Datepicker
import 'react-datepicker/dist/react-datepicker.css'
import {
  LayoutSplashScreen,
  MetronicLayoutProvider,
  // MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from './_metronic/layout'
import { MetronicI18nProvider } from './_metronic/i18n'
import './_custom/styles/index.scss'

const { PUBLIC_URL } = process.env
// _redux.setupAxios(axios, store);

const App = React.lazy(() => import('./app/App'))
const setLocationScript = () => {
  const maps = document.createElement('script')
  maps.src =
    'https://maps.google.com/maps/api/js?key=AIzaSyCIDUSBqHPfkEssENT_X9vuWt5nzca8_W4&libraries=places'
  document.body.appendChild(maps)
  maps.addEventListener('load', () => {
    console.log('maps loaded')
    window.locService = new window.google.maps.places.PlacesService(
      new window.google.maps.Map(document.getElementById('map'))
    )
  })
}

setLocationScript()

ReactDOM.render(
  <React.Suspense fallback={<LayoutSplashScreen />}>
    <MetronicI18nProvider>
      <MetronicLayoutProvider>
        <MetronicSubheaderProvider>
          {/* <MetronicSplashScreenProvider> */}
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
          <input id='map' hidden />
          {/* </MetronicSplashScreenProvider> */}
        </MetronicSubheaderProvider>
      </MetronicLayoutProvider>
    </MetronicI18nProvider>
  </React.Suspense>,
  document.getElementById('root')
)
