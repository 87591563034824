/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import { IoMdSettings, IoMdPeople, IoMdAppstore } from 'react-icons/io'
import { FiMapPin, FiPercent } from 'react-icons/fi'
import { FaMapMarked, FaStore } from 'react-icons/fa'
import { MdDashboard } from 'react-icons/md'
export function AsideMenuList({ layoutProps }) {
  const location = useLocation()
  const getMenuItemActive = url => {
    return checkIsActive(location, url)
      ? ' menu-item-active menu-item-open '
      : ''
  }

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/dashboard')}`}
          aria-haspopup='true'>
          <NavLink className='menu-link' to='/dashboard'>
            <span className='svg-icon menu-icon'>
              <MdDashboard />
            </span>
            <span className='menu-text'>Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/catalogue'
          )}`}
          data-menu-toggle='hover'
          aria-haspopup='true'>
          <NavLink className='menu-link menu-toggle' to='/catalogue'>
            <span className='svg-icon menu-icon'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
            </span>
            <span className='menu-text'>Catalogue</span>
            <i className='menu-arrow' />
          </NavLink>
          <div className='menu-submenu '>
            <ul className='menu-subnav'>
              <li
                className={`menu-item ${getMenuItemActive('/catalogue/menu')}`}
                aria-haspopup='true'>
                <NavLink className='menu-link' to='/catalogue/menu'>
                  <i className='menu-bullet menu-bullet-dot'>
                    <span />
                  </i>
                  <span className='menu-text'>Menu</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  '/catalogue/manager'
                )}`}
                aria-haspopup='true'>
                <NavLink className='menu-link' to='/catalogue/manager'>
                  <i className='menu-bullet menu-bullet-dot'>
                    <span />
                  </i>
                  <span className='menu-text'>Data manager</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        {/*end::1 Level*/}

        {/* Components */}
        {/* begin::section */}
        {/* <li className="menu-section ">
            <h4 className="menu-text">Components</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li> */}
        {/* end:: section */}

        {/* Material-UI */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/outlet')}`}
          aria-haspopup='true'>
          <NavLink className='menu-link menu-toggle' to='/outlet'>
            <span className='svg-icon menu-icon'>
              <FaStore />
              {/* <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Cap-2.svg')} /> */}
            </span>
            <span className='menu-text'>Outlets</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/locations')}`}
          aria-haspopup='true'>
          <NavLink className='menu-link menu-toggle' to='/locations'>
            <span className='svg-icon menu-icon'>
              <FiMapPin />
              {/* <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Cap-2.svg')} /> */}
            </span>
            <span className='menu-text'>Locations</span>
          </NavLink>
        </li>
        {/* <li
          className={`menu-item ${getMenuItemActive('/products')}`}
          aria-haspopup='true'>
          <NavLink className='menu-link menu-toggle' to='/products'>
            <span className='svg-icon menu-icon'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Cap-2.svg')} />
            </span>
            <span className='menu-text'>Products</span>
          </NavLink>
        </li> */}
        <li
          className={`menu-item ${getMenuItemActive('/discount')}`}
          aria-haspopup='true'>
          <NavLink className='menu-link menu-toggle' to='/discount'>
            <span className='svg-icon menu-icon'>
              <FiPercent />
            </span>
            <span className='menu-text'>Discount Engine</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Bootstrap */}
        {/*begin::1 Level*/}
        {/* <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/react-bootstrap"
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}/>
            </span>
              <span className="menu-text">Bootstrap</span>
              <i className="menu-arrow"/>
            </NavLink>
      
          </li> */}
        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}
        {/* <li className="menu-section ">
            <h4 className="menu-text">Applications</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li> */}
        {/* end:: section */}

        {/* eCommerce */}
        {/*begin::1 Level*/}
   
        {/* <li
          className={`menu-item ${getMenuItemActive('/customers')}`}
          aria-haspopup='true'
          data-menu-toggle='hover'>
          <NavLink className='menu-link menu-toggle' to='/customers'>
            <span className='svg-icon menu-icon'>
              <IoMdPeople />
            </span>
            <span className='menu-text'>Customers</span>
          </NavLink>
        </li> */}
   
   
        {/*end::1 Level*/}

        {/* Custom */}
        {/* begin::section */}
        <li className='menu-section '>
          <h4 className='menu-text'>Customize</h4>
          <i className='menu-icon flaticon-more-v2'></i>
        </li>
        {/* end:: section */}
        <li
          className={`menu-item ${getMenuItemActive('/apps')}`}
          aria-haspopup='true'>
          <NavLink className='menu-link' to='/apps'>
            <span className='svg-icon menu-icon'>
              <IoMdAppstore />
            </span>
            <span className='menu-text'>Apps</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/settings')}`}
          aria-haspopup='true'>
          <NavLink className='menu-link' to='/settings'>
            <span className='svg-icon menu-icon'>
              <IoMdSettings />
            </span>
            <span className='menu-text'>Settings</span>
          </NavLink>
        </li>

        {/* Error Pages */}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/error'
          )}`}
          aria-haspopup='true'
          data-menu-toggle='hover'>
          <NavLink className='menu-link menu-toggle' to='/error'>
            <span className='svg-icon menu-icon'>
              <IoMdSettings />
            </span>
            <span className='menu-text'>Error Pages</span>
            <i className='menu-arrow' />
          </NavLink>
          <div className='menu-submenu '>
            <i className='menu-arrow' />
            <ul className='menu-subnav'>
              <li className='menu-item  menu-item-parent' aria-haspopup='true'>
                <span className='menu-link'>
                  <span className='menu-text'>Error Pages</span>
                </span>
              </li>

              <li
                className={`menu-item ${getMenuItemActive('/error/error-v1')}`}
                aria-haspopup='true'>
                <NavLink className='menu-link' to='/error/error-v1'>
                  <i className='menu-bullet menu-bullet-dot'>
                    <span />
                  </i>
                  <span className='menu-text'>Error Page - 1</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive('/error/error-v2')}`}
                aria-haspopup='true'>
                <NavLink className='menu-link' to='/error/error-v2'>
                  <i className='menu-bullet menu-bullet-dot'>
                    <span />
                  </i>
                  <span className='menu-text'>Error Page -2</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive('/error/error-v3')}`}
                aria-haspopup='true'>
                <NavLink className='menu-link' to='/error/error-v3'>
                  <i className='menu-bullet menu-bullet-dot'>
                    <span />
                  </i>
                  <span className='menu-text'>Error Page - 3</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}
